import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import EventRegistration from "./components/EventRegistration";

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <header className="App-header">
          <main>
            <EventRegistration />
          </main>
        </header>
      </div>
    </HelmetProvider>
  );
}

export default App;
