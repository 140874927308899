import "../App.css";
import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { doc, arrayUnion, updateDoc, getDoc } from "firebase/firestore";
import { isAndroid, isIOS } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import TermsCons from "./TermsCons";

const EventRegistration = () => {
  const windowUrl = window.location.search;
  const urlParams = new URLSearchParams(windowUrl);
  const [eventId, eventIdSet] = useState(
    urlParams.get("eid") ? urlParams.get("eid") : "eventable"
  );
  const [eventData, eventDataSet] = useState({});
  const [displayName, displayNameSet] = useState("");
  const [phoneNumber, phoneNumberSet] = useState("");
  const [dateLang, dateLangSet] = useState("en");
  const [isLoading, isLoadingSet] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const isLargeScreen = width > 768;

  const urlHome = "https://eventable-e30d5.web.app/";
  const urlAppStore = "https://apps.apple.com/us/app/eventable+/id1669476514";
  const urlPlayStore =
    "https://play.google.com/store/apps/details?id=com.enlcy.eventableapp";

  const eventRef = doc(db, "PROD_Events", eventId);
  const premiumRef = doc(db, "PROD_Config", "premium");
  const participantRef = doc(
    db,
    "PROD_Events",
    eventId,
    "participant",
    "participant"
  );

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (!urlParams.get("eid") || urlParams.get("eid") === "termscons") return;
    fetchEventData();
  }, []);

  const register = async (e) => {
    isLoadingSet(true);
    e.preventDefault();

    if (!displayName || !phoneNumber) {
      isLoadingSet(false);
      return alert(
        "Name & Phone Number cannot be null.\n姓名 及 聯絡電話 不能留空。"
      );
    }
    try {
      let premiumSnap = await getDoc(premiumRef);
      let participantLimit =
        premiumSnap.data().participantLimit[eventData.premium];
      let participantSnap = await getDoc(participantRef);
      let participantData = participantSnap.data().member || [];
      let participantCount = participantData.length;
      if (participantCount >= participantLimit) {
        isLoadingSet(false);
        return alert(
          "Sorry, this event reached the max. registration limitation.\n很抱歉，此活動已到達報名人數上限。"
        );
      }

      await updateDoc(participantRef, {
        member: arrayUnion({
          uid: Date.now(),
          displayName: displayName,
          phoneNumber: phoneNumber,
        }),
      });
      displayNameSet("");
      phoneNumberSet("");
      alert("Register successfully.\n登記完成。");
      isLoadingSet(false);
    } catch (e) {
      isLoadingSet(false);
      console.error("Error adding document: ", e);
    }
  };

  const openApp = async () => {
    if (isAndroid) {
      const url = `intent://eid/${eventId}#Intent;scheme=enlcyeventable;package=com.enlcy.eventableapp;end`;

      window.location.replace(url);
    } else if (isIOS) {
      setTimeout(function () {
        window.location = urlAppStore;
      }, 25);
      window.location = `enlcyeventable://eid/${eventId}`;

      // window.location.replace(`enlcyeventable://eid/${eventId}`);

      // setTimeout(() => {
      //   window.location.replace(urlAppStore);
      // }, 7000);
    } else {
      window.open(urlHome, "_blank");
    }
  };

  const fetchEventData = async (e) => {
    try {
      let eventSnap = await getDoc(eventRef);
      if (!eventSnap.exists()) return eventIdSet("eventNotFound");
      eventDataSet(eventSnap.data());
      // console.log("Document:", eventSnap.data());
    } catch (e) {
      console.error("Error reading document: ", e);
    }
  };

  const handleChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    phoneNumberSet(result);
  };

  if (!eventId || eventId === "eventable")
    return (
      <section className="eventreg-container">
        <div>
          <img
            src={require("../assets/logo_ac.png")}
            // className="App-logo"
            alt="logo"
            style={{ width: "100%" }}
          />
          <h1 className={isLargeScreen ? "largeheader" : "header"}>
            EventAble
          </h1>
          <div className="header">
            <img
              src={require("../assets/download_ios.png")}
              // className="App-logo"
              alt="logo"
              style={{
                marginInline: 10,
                marginTop: 10,
                width: isLargeScreen ? "30%" : "60%",
              }}
              onClick={() => window.location.replace(urlAppStore)}
            />
            <img
              src={require("../assets/download_apk.png")}
              // className="App-logo"
              alt="logo"
              style={{
                marginInline: 10,
                marginTop: 10,
                width: isLargeScreen ? "30%" : "60%",
              }}
              onClick={() => window.location.replace(urlPlayStore)}
            />
          </div>
        </div>
      </section>
    );

  if (eventId === "termscons") return <TermsCons />;

  if (eventId === "eventNotFound")
    return (
      <section className="eventreg-container">
        <div>
          <img
            src={require("../assets/logo_ac.png")}
            // className="App-logo"
            alt="logo"
            style={{ width: "100%" }}
          />
          <h1 className={isLargeScreen ? "header" : "smallheader"}>
            Event not found
          </h1>
          <h1 className={isLargeScreen ? "header" : "smallheader"}>
            活動不存在
          </h1>
        </div>
      </section>
    );

  const dateFormat = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      {/* <head>
        <meta name="description" content={`${eventData?.title}`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:URL"
          content={`${
            eventData?.thumbnail || require("../assets/logo_ac.png")
          }`}
        />
        <meta
          property="og:image"
          content={`${
            eventData?.thumbnail || require("../assets/logo_ac.png")
          }`}
        />
      </head> */}
      <Helmet prioritizeSeoTags>
        <title>{eventData?.title}</title>
        <link rel="canonical" href={urlHome} />
        <meta property="og:title" key="og:title" content={eventData?.title} />
        <meta property="og:type" content="website" />
        <meta property="og:URL" content={`${urlHome}?eid=${eventId}`} />
        <meta
          property="og:image"
          content={eventData?.thumbnail || require("../assets/logo_ac.png")}
        />
      </Helmet>
      <section className="eventreg-container">
        {/* This is the modal that is hidden by default */}
        <div style={{ display: isLoading ? "flex" : "none" }} className="modal">
          <div className="modal-content">
            <div className="loader"></div>
            <div className="modal-text">Loading...</div>
          </div>
        </div>

        {/* This is the main body */}
        <div className="eventreg">
          <img
            className="img"
            src={
              eventData.thumbnail
                ? eventData.thumbnail
                : require("../assets/logo_ac.png")
            }
            alt="logo"
            // resizeMode="center"
            style={
              eventData.thumbnail
                ? { aspectRatio: 16 / 9 }
                : { aspectRatio: 1 / 1, maxWidth: 400 }
            }
          />
          <h1 className={isLargeScreen ? "header" : "smallheader"}>
            {eventData.title}
          </h1>

          <div
            className={
              isLargeScreen ? "eventreg-content" : "eventreg-content-small"
            }
          >
            <table style={{ width: "100%", paddingInline: 5 }}>
              {/* <tr>
              <th>Name</th>
              <th>Age</th>
            </tr> */}
              <tbody>
                {eventData.startDate ? (
                  <tr>
                    <td
                      width={isLargeScreen ? 130 : 110}
                      style={{ fontWeight: "bold" }}
                    >
                      Date / 日期：
                    </td>
                    <td>
                      {eventData.startDate
                        .toDate()
                        .toLocaleDateString(dateLang, dateFormat) +
                        " - " +
                        eventData.endDate
                          .toDate()
                          .toLocaleDateString(dateLang, dateFormat)}
                      <button
                        style={{
                          alignSelf: "center",
                          marginInline: 10,
                          backgroundColor: "yellow",
                        }}
                        onClick={() =>
                          dateLang === "en"
                            ? dateLangSet("zh-hk")
                            : dateLangSet("en")
                        }
                      >
                        {dateLang === "en" ? "中文" : "Eng"}
                      </button>
                    </td>
                  </tr>
                ) : null}
                {eventData.time ? (
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Time / 時間：</td>
                    <td>{eventData.time}</td>
                  </tr>
                ) : null}
                {eventData.venue ? (
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Venue / 地點：</td>
                    <td>{eventData.venue}</td>
                  </tr>
                ) : null}
                {eventData.remarks ? (
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Remarks / 備註：</td>
                    <td>{eventData.remarks}</td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>

          <div style={{ marginTop: 10 }}>
            <div
              // className="btn-container"
              style={{
                display: "flex",
                flexDirection: isLargeScreen ? "row" : "column",
              }}
              // style={{ display: "flex" }}
            >
              <input
                type="text"
                placeholder="Name / 姓名"
                maxLength={20}
                value={displayName}
                onChange={(e) => displayNameSet(e.target.value)}
                style={{
                  flex: 1,
                  margin: 5,
                  minHeight: isLargeScreen ? 30 : 20,
                }}
              />
              <input
                value={phoneNumber}
                maxLength={20}
                placeholder={"Contact Phone Number / 聯絡電話號碼"}
                onChange={handleChange}
                style={{
                  flex: 1,
                  margin: 5,
                  minHeight: isLargeScreen ? 30 : 20,
                }}
              />
            </div>

            <div className="btn-container" style={{ marginBottom: 20 }}>
              <button
                type="submit"
                className="btn"
                disabled={isLoading}
                onClick={register}
              >
                Register / 報名
              </button>
            </div>
            <div className="btn-container" style={{ marginBottom: 50 }}>
              <button type="submit" className="btn-open-app" onClick={openApp}>
                <img
                  // className="img"
                  src={require("../assets/logo_ac.png")}
                  alt="logo"
                  style={{ height: 30, width: 30 }}
                />
                Browse Event in App / 應用程式中瀏覽
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventRegistration;
