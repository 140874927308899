import "../App.css";
import React from "react";
import { Helmet } from "react-helmet-async";

const TermsCons = () => {
  const urlHome = "https://eventable-e30d5.web.app/";
  const fontSizeHeader = 16;
  const fontSizeText = 12;

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>EventAble - Terms & Conditions and Privacy Policy</title>
        <link rel="canonical" href={urlHome} />
      </Helmet>
      <section style={{ display: "flex", padding: 15 }}>
        <div>
          <h1 style={{ fontSize: 24, marginLeft: "left" }}>
            Terms & Conditions and Privacy Policy
          </h1>
        </div>
      </section>
      <div style={{ display: "flex", paddingInline: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          TERMS & CONDITIONS
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          Throughout this document, the words “us”, “we”, “our” refer to us, or
          our service as is appropriate in the context of the use of the words
          “you” and “your” refer to you, the person who is being presented with
          this document for your agreement. Access to and use of this app and
          the products and services available through this app are subject to
          the following terms, conditions and notices (the “Terms of Service” or
          “ToS”). By using the products and services, you are agreeing to all of
          the Terms of Service, as may be updated by us from time to time. We
          reserves the right to make changes to the app, related polices and
          agreements, the privacy policy and this ToS at any time. You are
          advised to check the app as often as possible to check for updates or
          changes to the said policies and agreements, the privacy policy and
          the ToS. If there is any inconsistency between the English and Chinese
          versions of these Terms & Conditions and the Privacy Policy below
          (unless otherwise indicated), the English version shall prevail.
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          As your Service provider, we respects your legal rights of privacy
          when collecting, storing, using and transmitting Personal Information
          (as defined below) and this Privacy Policy explains our privacy
          practices. It is our policy and obligation to comply with the
          requirements of the Personal Data (Privacy) Ordinance (Cap. 486 of the
          laws of Hong Kong). In doing so, we will ensure compliance by our
          Associates to the strictest standard of security and confidentiality.
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          Please read the following carefully to understand our policy and
          practices regarding how your Personal Information will be treated.
          This Privacy Policy applies to all registered and unregistered Users
          of the Sites and may from time to time be revised, or otherwise
          changed where necessary. <br />
          Words or terms used in this Privacy Policy which are not specifically
          defined in this Privacy Policy have the meaning given to them in the
          Agreement (as defined below).
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          By being one of our Users or visitors to our Sites you agree to be
          bound by all the terms and conditions set out in this Privacy Policy
          and THAT YOU GIVE US YOUR CONSENT to collect, use and disclose such
          Personal Information. If you do not accept the terms of this Privacy
          Policy or disagree with any subsequent amendments, changes, or updates
          we made, you MUST NOT access the Sites and not use any Service
          provided by us. You hereby agree your only recourse in this case is to
          cease and desist from further use of the Service.
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          DEFINITIONS AND INTREPRETATIONS
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          The following shall have the meanings described herein:
          <br />
          (a) “Agreement” means the user agreement entered between us as the
          provider of the Service on the one part and you as the User on the
          other made effective upon your continued use of the Sites and/or
          Service. <br />
          (b) “Account” means an established relationship between the User and
          the network of information service provided by us including the
          username and password created by the User at his own initiative or
          provided by us and accepted by us for the purpose to enable the User
          to gain access to the Service provided by us. <br />
          (c) “Associates” means each and every one of our respective
          co-conspirators, partners and friends. <br />
          (d) “Internet” means a global computer network through which the
          almost-instant delivery of data or files occurs between connected
          computers. <br />
          (e) “Minor” means a person under the age of 18 (or the age that a
          person is permitted to enter into a contractual relationship under
          applicable law at your jurisdiction). <br />
          (f) “Non-Personal Information” means information that does not
          identify you as an individual as more stipulated in Clause 3.7. <br />
          (g) “Parent” or “Legal Guardian” means the person who has the legal
          authority (and the corresponding duty) to care for the person and
          property interest of a User who is a Minor. <br />
          (h) “Person” includes an individual, association, partnership,
          corporation, other body corporate, trust, and any form of legal
          organisation or entity. <br />
          (i) “Personal Information” means information about an identifiable
          individual, business, organisation or other entity, but does not
          include the name, title, business address, or telephone number of an
          employee and/or associate, partners of a business, organisation or
          other entity. <br />
          (j) “Privacy Policy” means this policy. <br />
          (k) “Service” means any of the services, functions, or features
          offered on the Sites provided by us for online collaboration and/or
          management including but not limited to point-of-sales systems, office
          automation systems and human resources management systems. Service may
          also be equivalent to “Project”. <br />
          (l) “Site(s)” means the website(s) including but not limited to
          (www.enlcy.com, or eventable-e30d5.web.app.com) or any other website
          (s) and sub-links owned and operated by us. <br />
          (m) “Users” means those individuals, employees, contractors and end
          users, as applicable, authorized by you or on your behalf to use the
          Service in accordance with the Agreement.
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          The headings and sub-headings in this Privacy Policy are for ease of
          reference only and are not to be taken into account in the
          construction or interpretation of any provision or provisions to which
          they refer. Unless otherwise specified in this Privacy Policy, words
          importing the singular include the plural and vice versa and words
          importing gender include all genders.
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          1.0 COLLECTION OF PERSONAL INFORMATION
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          1.1 The types of information collected by us include Personal
          Information and Non-Personal Information. <br />
          1.2 We may also receive, collect and store any Personal Information
          including but not limited to useless personal and/or latest gossip you
          provide to us when you or your group, collective or business: <br />
          (a) enquire of or make an application for Service; <br />
          (b) register to use and/or use any Service;
          <br />
          (c) upload and/or store information with us using the Service; or{" "}
          <br />
          (d) when you communicate with us through e-mail, SMS, a website or
          portal, or the telephone or other electronic means.
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          2.0 DISCLOSURE OF INFORMATION
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          2.1 We will never sell, share or mine your Personal Information with
          strategic partners, third parties or service providers.
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          3.0 YOUR PERSONAL INFORMATION
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          3.1 You are free to decide whether to provide, edit or remove your
          Personal Information disclosed in the Service. Except your username,
          you are free to amend your Personal Information which have been
          submitted to us and the profile information of the Account so long as
          those Personal Information are true and accurate. The action can be
          performed directly on your profile in the App. <br />
          3.2 You may opt out of receiving direct communication materials from
          us by following the instructions provided in such communiqué. Should
          you decide to opt out from receiving such materials, we may still send
          you non-promotional communications, such as those about the Account,
          previous purchases, our ongoing relations or change in operation, etc.
          <br />
          3.3 In accordance with the statutory requirements, we will honour your
          request not to use your Personal Information for the purposes of
          direct marketing.
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          4.0 USE OF PERSONAL INFORMATION
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          4.1 We collect Personal Information that you and your co-conspirators
          provide directly to us. <br />
          4.2 The ways of which we may use your Personal Information, once
          collected, may include but not limited to the followings: <br />
          (a) to keep you posted on our latest announcements, software updates,
          and upcoming events. <br />
          (b) to help us create, develop, operate, deliver, and improve our work
          and products, Service and content, and for loss prevention and
          anti-fraud purposes. <br />
          (c) to verify identity, assist with identification of Users, and to
          determine appropriate Service. <br />
          (d) to send important notices, such as communications about purchases
          and changes to our terms, conditions and policies. <br />
          (e) for our internal purposes, such as auditing, data analysis,
          gossip-mongering and research to improve our products, Service, and
          customer communications. <br />
          4.3 We may review the Personal Information from time to time to
          identify problems and solve disputes, and to identify Users who use
          multiple user identifications, codenames or aliases. We may compare
          and review your Personal Information for errors, omissions and
          accuracy.
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          5.0 CHANGE OF THIS PRIVACY POLICY
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          paddingInline: 15,
          marginTop: 5,
          marginBottom: 25,
        }}
      >
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          5.1 We may amend this Privacy Policy at any time by e-mail, or via
          posting the amended Privacy Policy on the Sites. All amended Privacy
          Policy shall automatically be effective [15] days after the posting
          day of such amended Privacy Policy. Your continued use of the Service
          after the effective date of such amended terms and/or conditions
          constitutes your acceptance of the amended Privacy Policy.
        </span>
      </div>
      <section style={{ display: "flex", padding: 15 }}>
        <div>
          <h1 style={{ fontSize: 24, marginLeft: "left" }}>
            條款及細則 和 私隱政策
          </h1>
        </div>
      </section>
      <div style={{ display: "flex", paddingInline: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          條款及細則
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          在這份文件中，「我們」、「我們的」等詞彙，指的是我們或我們的服務，當上下文合適的時候，用“您”和“您的”指您，比如這份文件正在被您閱覽，等待您的同意。瀏覽及使用這個應用程式以及應用程式內所提供的產品和服務服從于以下這些條款和注意事項（「服務條款」）。通過使用這些產品和服務，您需要同意全部的服務條款，而且我們也會不斷更新這些條款。我們保留有權力在任何時間修改此網站內容、相關政策及協議，包括私隱政策及服務條款。建議您間中使用本程式查看最新的相關政策及協議、私隱政策及服務條款。在此條款——「條款」和「私隱政策」——中若有英文版本和中文版本的不一致，（除非另有說明）請以英文條款為準。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          作為您的服務提供商，我們在收集、存儲、使用和傳輸個人資料（如下文中的定義）時將尊重您的合法私隱權。本私隱政策說明了我們的私隱慣例。我們的政策和義務遵守《個人資料（私隱）條例》（香港法例第486章）的要求。在這種情況下，我們將確保我們的工作人員遵守最嚴格的安全標準和保密標準。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          請仔細閱讀以下內容，以瞭解我們關於如何處理您的個人資料的政策和慣例。本私隱政策適用於本網站和服務應用程式的所有已註冊和未註冊的用戶，並可不時進行修訂，或在必要時進行其他更改。
          <br />
          本私隱政策中所使用但未在本私隱政策中特別定義的字句或術語具有協議（如下所定義）賦予其的含義。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          作為我們的用戶或我們的網站和/或服務應用程式的訪客，您同意接受本私隱政策中規定的所有條款和條件的約束，並同意我們可收集、使用和披露此類個人資料。若您不接受本私隱政策的條款或不同意我們所做的任何後續修訂、更改或更新，您不得訪問網站或服務應用程式，也不得使用我們提供的任何服務。您特此同意，在這種情況下，您唯一的辦法是停止進一步使用服務。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          定義和解釋
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          以下具有下文描述的含義： <br />
          (a)
          「協議」指我們作為服務提供者（一方）和您作爲用戶（另一方）在您繼續使用本網站和/或服務時生效的用戶協議。
          <br />
          (b)
          「帳戶」指用戶與我們提供的資料服務網路之間已建立的關係，包括由用戶自行創建或由我們提供並由我們接受的用戶名稱和密碼，以便用戶可獲得訪問我們提供的服務的權限。
          <br />
          (c) 「有關連人士」指我們各自的同謀、搭檔和朋友。 <br />
          (d)
          「網際網路」指通過其在連線電腦之間幾乎即時傳送數據或文件的全球電腦網路。
          <br />
          (e)
          「未成年人」指未滿18歲的人（或根據適用法律在您所在司法管轄區允許某人簽訂合約的年齡）。
          <br />
          (f) 「非個人資料」指不會將您識別為您個人的信息。 <br />
          (g)
          「父母」或「法定監護人」指具有法律權力（和相應的義務）去照顧未成年人用戶人身安全和財產利益的人。
          <br />
          (h)
          「人士」包括個人、協會、合夥、企業、其他法人團體、信託和任何形式的法律組織或實體。
          <br />
          (i)
          「個人資料」指關於可識別個人、企業、組織或其他實體的資料，但不包括僱員和/或協會、企業、組織或其他實體的合作夥伴的名稱、職稱、業務地址或電話號碼。
          <br />
          (j) 「私隱政策」指本政策。 <br />
          (k)
          「服務」指網站上或服務應用程式中由我們提供的用於網上協作和/或管理的任何服務、功能或特色功能，包括但不限於銷售點系統、自動化辦公系統和人力資源管理系統。服務也等同於項目。
          <br />
          (l) 「網站」指網站，包括但不限於（www.enlcy.com, 或
          eventable-e30d5.web.app.com）或我們擁有和運營的任何其他網站，社交媒體頻道，及其子鏈結。
          <br />
          (m)
          「用戶」指由您根據協議授權或根據協議代表您使用服務的個人、僱員、承辦商和最終用戶（如適用）。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          本私隱政策中的標題和子標題僅供參考，在詮釋或解釋其所引用的任何條款或眾條款時不應被考慮。
          <br />
          除非本私隱政策中另有規定，否則單數字句包括複數，反之亦然，性別字句包括所有性別。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          1.0 個人資料收集
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          1.1 我們收集的資料類型包括個人資料和非個人資料。 <br />
          1.2
          我們還可收取、收集和存儲任何個人資料，包括但不限於您、您的小組或團隊在處理以下事項時向我們提供的無用的個人資料、最近的八卦：
          <br />
          (a) 查詢或申請服務； <br />
          (b) 註冊使用和/或使用任何服務； <br />
          (c) 使用服務上傳和/或存儲資料；或 <br />
          (d)
          當您通過電郵、手機短信、網站或門戶，或電話或其他電子設施與我們進行通訊時。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          2.0 資料披露
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          2.1
          我們不會跟戰略夥伴、第三方或服務提供商分享、賣或用數據挖掘技術來收集您的個人資料。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          3.0 您的個人資料
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          3.1
          您可以自由決定是否提供、編輯或刪除您在服務中披露的個人資料。除您的用戶名稱外，您可以自由修改已提交給我們的個人資料和帳戶資料，只要這些個人資料真實且準確。該操作可以直接在服務應用程式內您的個人主頁上執行。
          <br />
          3.2
          您可以按照這些直接促銷推廣資料中提供的說明，選擇不接收我們的直接促銷推廣資料。若您決定不接收此類資料，我們仍會向您發送非促銷性通訊，例如關於帳戶、先前購買、我們進行中的業務關係或業務變更等。
          <br />
          3.3
          根據法定要求，我們將遵守您的要求，不將您的個人資料用於直接促銷推廣。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          4.0 個人資料的使用
        </h1>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 5 }}>
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          4.1
          我們收集您和您的同謀、朋友、家人、員工、您的附属公司或您的承辦商直接向我們提供的個人資料。
          <br />
          4.2 我們使用您的個人資料的方式（在收集後）可能包括但不限於以下方面：
          <br />
          (a) 通知您我們最新的產品公告、程式更新和即將舉行的活動。 <br />
          (b)
          幫助我們創建、開發、運營、提供和改進我們的工作、產品、服務、內容和廣告，以及防止損失及防止欺詐。
          <br />
          (c) 驗證身份、協助識別用戶、以及確定適當的服務。 <br />
          (d) 發送重要通知，例如關於購買的通訊和對我們的條款、條件和政策的更改。{" "}
          <br />
          (e)
          用於我們的內部目的，如審計、數據分析、八卦傳播和研究，以改進我們的產品、服務和客戶溝通。
          <br />
          4.3
          我們可能會不時審查個人資料，以確定問題並解決爭議，並識別使用多個用戶身份、代號或別名的用戶。我們可能會比較和審查您的個人資料中的錯誤、遺漏和準確性。
        </span>
      </div>
      <div style={{ display: "flex", paddingInline: 15, marginTop: 15 }}>
        <h1 style={{ fontSize: fontSizeHeader, marginLeft: "left" }}>
          5.0 本私隱政策的更改
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          paddingInline: 15,
          marginTop: 5,
          marginBottom: 25,
        }}
      >
        <span style={{ fontSize: fontSizeText, textAlign: "justify" }}>
          5.1
          我們可以隨時通過電郵或通過在網站上發布已修訂私隱政策來修訂本私隱政策。所有已修訂私隱政策將在此類修訂私隱政策發布後[15]天內自動生效。若您在此類已修訂條款和/或條件生效之後繼續使用本服務，即表示您接受修訂的私隱政策。
        </span>
      </div>
    </>
  );
};

export default TermsCons;
